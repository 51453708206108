<template>
    <div>
    <ContentHeader title="Data Jurnal Singkat" url="/data-jurnal-singkat" subTitle="Tambah Data Jurnal Singkat" />
     <div class="content-header">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col">
                    <div class="card">
                        <div class="card-header row d-flex align-items-center">
                            <h3 class="card-title col-6">Tambah Data Jurnal Singkat</h3>
                        </div>
                        <div class="card-body row">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-8">
                                <form class="pl-2" @submit.prevent="prosesJurnalSingkat">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="nomor_rekam_medis">Jenis <span>*</span></label>
                                                <select class="custom-select form-control" required="" v-model="jurnal_singkat.jenis">
                                                    <option>- Pilih Jenis Jurnal -</option>
                                                    <option value="penerimaan kas">Penerimaan Kas</option>
                                                    <option value="pengeluaran kas">Pengeluaran Kas</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                           <div class="form-group">
                                                <label for="nomor_rekam_medis">Akun Debit <span>*</span></label>
                                                <multiselect 
                                                  v-model="debit_select_value"
                                                  deselect-label="Can't remove this value"
                                                  track-by="nama"
                                                  label="nama"
                                                  placeholder=""
                                                  :custom-label="customSelect"
                                                  :options="akun_keuangan"
                                                  :allow-empty="false"
                                                  :loading="memuat_data"
                                                  @update:model-value="debit_select_change"
                                                >
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                           <div class="form-group">
                                                <label for="nomor_rekam_medis">Akun Kredit <span>*</span></label>
                                                <multiselect 
                                                  v-model="kredit_select_value"
                                                  deselect-label="Can't remove this value"
                                                  track-by="nama"
                                                  label="nama"
                                                  placeholder=""
                                                  :custom-label="customSelect"
                                                  :options="akun_keuangan"
                                                  :allow-empty="false"
                                                  :loading="memuat_data"
                                                  @update:model-value="kredit_select_change"
                                                >
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="nomor_rekam_medis">Deskripsi <span>*</span></label>
                                                <textarea class="form-control" style="min-height: 100px" required="" v-model="jurnal_singkat.deskripsi"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
                                        <div>Submit</div>   
                                        <div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
                                          <span class="sr-only">Loading...</span>
                                        </div>
                                    </button>
                                </form>
                                <div class="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import { ref, onMounted, computed, reactive } from 'vue'
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import Swal from 'sweetalert2'
import Multiselect from '@suadelabs/vue3-multiselect'

export default{
    components: {
        ContentHeader,
        Multiselect
    },
    setup(){
        const user = computed(() => store.getters['auth/user'])
        const akun_keuangan = ref([])
        const jurnal_singkat = reactive({
            'cabang_id' : user.value.cabang_id,
            'jenis' : '',
            'akun_debit_id' : '',
            'akun_kredit_id' : '',
            'deskripsi' : ''
        })
        const loading = ref(false)
        const memuat_data = ref(true)

        const debit_select_value = ref('')
        const customSelect = ({ kode, nama }) => {
          return `${kode} - ${nama}`
        }
        const debit_select_change = () => {
            jurnal_singkat.akun_debit_id = debit_select_value.value.id
        }

        const kredit_select_value = ref('')
        const kredit_select_change = () => {
            jurnal_singkat.akun_kredit_id = kredit_select_value.value.id
        }


        const getAkunKeuangan = async () => {
            let { data } = await axios.get(`api/akun_keuangan/getAll`)

            if (data != 'kosong') {
                akun_keuangan.value = data
                memuat_data.value = false
            } else {
                akun_keuangan.value = []
                memuat_data.value = false
            }
        }


        const prosesJurnalSingkat = () => {
            loading.value = true
            axios.post('api/jurnal_singkat/create', jurnal_singkat)
            .then((response) => {
                Swal.fire({
                  title: 'Berhasil!',
                  text: 'Berhasil Menanbahkan Data',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500
                })  
                loading.value = false
                console.log(response)
                router.push('/data-jurnal-singkat')
            })
            .catch((error) => {
                Swal.fire({
                  title: 'Gagal!',
                  text: 'Gagal Menanbahkan Data',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: 1500
                })  
                loading.value = false
                console.log(error)
            })
        }

        onMounted(() => {
            getAkunKeuangan()
        })

        return { akun_keuangan, jurnal_singkat, prosesJurnalSingkat, loading, debit_select_value, customSelect, debit_select_change, kredit_select_value, kredit_select_change, memuat_data }
    }
}
</script>

<style>
    
</style>